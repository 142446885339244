import { SleepDiaryGraph } from '@component/charts/sleep-diary-graph.component';
import { getDiariesWithEmptyValues } from '@component/charts/utils';
import { GatsbyButton } from '@components/atm.button/button.component';
import { ChatfuelBroadcastRequest } from '@global/utils/chat/chatfuel';
import { parseToDate } from '@global/utils/date/parse-to-date';
import { parseDateToShortString } from '@global/utils/date/parse-to-string';
import { SleepRestrictionSummaryTable } from '@lp-root/src/components/mol.sleep-restriction-summary-table/sleep-restriction-summary-table.component';
import {
  compareSleepDataSummaryItem,
  useSleepRestrictionSummary,
} from '@lp-root/src/components/mol.sleep-restriction-summary-table/sleep-restriction-summary-table.utils';
import { Col, FaIcon, Grid, H1, H2, Row, Separator } from '@web/atomic';
import { StickButtonWrapper } from '@web/atomic/atm.wrapper/wrapper.component';
import { Placeholder } from '@web/atomic/legacy/mol.placeholder';
import { ShimmerInput } from '@web/atomic/legacy/mol.shimmer/shimmer.options';
import { LoadingState } from '@web/atomic/legacy/obj.loading-state';
import { useClientRect } from '@web/atomic/obj.custom-hooks/client-rect.hook';
import { useCloseMessengerModalCallback } from '@web/atomic/obj.custom-hooks/close-messenger-modal.hook';
import { usePostUsingMessenger } from '@web/data/use-post-using-messenger.hook';
import { useQueryCustom } from '@web/data/use-query-custom.hook';
import { UserIdDataSource } from '@web/data/user-id.datasource';
import { PostUrl } from '@web/data/vigilantes.datasource';
import { getCanonicalUrl } from '@web/utils/url';
import { graphql } from 'gatsby';
import * as React from 'react';
import { SEO } from '../../components/legacy/mol.seo/seo.component';
import WebviewLayout from '../../components/org.layout/webview-layout.component';
import { SleepRestrictionGraphQuery } from '../../data/graphql/hasura/sleep-restriction-graph.query.hasura';
import { buildChatfuelBroadcastRequest } from '../../data/request-utils/redirect.request';
import { PageProps } from '../../utils/local-types';

const GraficoRestricaoDoSonoPage: React.FunctionComponent<PageProps> = (props) => {
  const { data, error, loading } = useQueryCustom(SleepRestrictionGraphQuery);

  const summary = useSleepRestrictionSummary(data);

  const numMedicine = summary.currentCycleDiaries?.filter((d) => {
    return d.tags.some((t) => t.sleep_tag === 'medicine');
  }).length;

  const medicineUse = Math.floor((100 * numMedicine) / summary.currentCycleDiaries?.length);
  const triedMedicine = medicineUse > 0 ? true : undefined;

  const [rect, titleRef] = useClientRect();
  const { close, loading: waitCloseLoading } = useCloseMessengerModalCallback();
  const [post, { loading: postLoading }] = usePostUsingMessenger<ChatfuelBroadcastRequest>({
    onSuccess: close,
    url: PostUrl.RedirectUser,
  });
  const closeLoading = waitCloseLoading || postLoading;

  const handleClose = async () => {
    if (!summary.currentCycleSummary.eficiency) {
      close();
      return;
    }

    const last = compareSleepDataSummaryItem(summary.currentCycleSummary, summary.lastCycleSummary);
    const all = compareSleepDataSummaryItem(summary.currentCycleSummary, summary.everythingSummary);
    console.log('DIARY_DEBUG: grafico-restricao-do-sono.tsx ~ line 55 ~ handleClose ~ userId');
    const userId = await UserIdDataSource.getId();
    const request = buildChatfuelBroadcastRequest(userId, 'sleep_restriction_other_s4', {
      'global-sleep_restriction_current_eficiency': summary.currentCycleSummary.eficiency.toFixed(1),
      'global-sleep_restriction_last_bed': last.minutesInBed,
      'global-sleep_restriction_last_sleep': last.minutesSleep,
      'global-sleep_restriction_last_eficiency': last.eficiency,
      'global-sleep_restriction_all_bed': all.minutesInBed,
      'global-sleep_restriction_all_sleep': all.minutesSleep,
      'global-sleep_restriction_all_eficiency': all.eficiency,
      'global-sleep_restriction_last_medicine': medicineUse.toString(),
      'global-tried_medicine': triedMedicine,
    });
    return post(request);
  };

  const currentCycleComments =
    summary.currentCycleDiaries && summary.currentCycleDiaries.length > 0 && summary.currentCycleDiaries.filter((item) => item.comment);

  const diaries = getDiariesWithEmptyValues(data && data.sleep_diary);

  return (
    <WebviewLayout>
      <SEO
        socialMedia={{
          canonicalUrl: getCanonicalUrl(props.data.site.siteMetadata.siteUrl, props.location.pathname),
          title: 'Dados do seu sono',
        }}
      />

      <Grid>
        <Row mt mb>
          <Col xs={12}>
            <H1 ref={titleRef}>Dados do seu sono</H1>
          </Col>
        </Row>
        <LoadingState loading={loading} error={!!error} data={!!diaries}>
          {/* TODO: UI - ajustar loading */}
          <LoadingState.Shimmer>
            <>
              {Array(3)
                .fill(1)
                .map((_item, index) => (
                  <Row mt mb key={index}>
                    <Col xs={12} sm={12} md={7} lg={7}>
                      <ShimmerInput />
                      <Separator />
                    </Col>
                  </Row>
                ))}
            </>
          </LoadingState.Shimmer>
          <LoadingState.Error>
            <Placeholder
              icon={<FaIcon.FlashAlert size="9x" />}
              title={'Erro ao pegar seus dados'}
              description="Tente novamente mais tarde"
            />
          </LoadingState.Error>
          <LoadingState.NoData>
            <Placeholder
              icon={<FaIcon.Table size="9x" />}
              title={'Seu diário está em branco'}
              description={`Quando você preencher seu diário, os seus dados podem ser vistos nessa tela, ok? Preencha 1 vez e veja como fica aqui!`}
            >
              <GatsbyButton onClick={close}> Combinado! </GatsbyButton>
            </Placeholder>
          </LoadingState.NoData>

          <Row mt>
            <Col xs={12}>
              <H2> Resumo </H2>
            </Col>
          </Row>
          <SleepRestrictionSummaryTable summary={summary} />

          {currentCycleComments && currentCycleComments.length > 0 && (
            <Row mt>
              <Col xs={12}>
                <H2> Comentários do diário nesse ciclo </H2>
                <ul>
                  {currentCycleComments.map((item) => (
                    <li key={item.date}>
                      <strong>{parseDateToShortString(parseToDate(item.date))}</strong>: <q>{item.comment}</q>
                    </li>
                  ))}
                </ul>
              </Col>
            </Row>
          )}

          {data && (
            <Row mt mb>
              <Col xs={12}>
                <H2> Todos dados </H2>
                <SleepDiaryGraph width={rect && rect.width} sleepDiaryData={diaries} />
              </Col>
            </Row>
          )}
          {!loading && (
            <StickButtonWrapper>
              <GatsbyButton onClick={handleClose} expanded loading={closeLoading}>
                Continuar
              </GatsbyButton>
            </StickButtonWrapper>
          )}
        </LoadingState>
      </Grid>
    </WebviewLayout>
  );
};

export default GraficoRestricaoDoSonoPage;

export const query = graphql`
  query GraficoRestricaoDoSonoPage {
    site {
      ...SiteUrl
    }
  }
`;
