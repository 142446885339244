import gql from 'graphql-tag';

export const SleepRestrictionGraphQuery = gql`
  query SleepRestrictionGraphQuery {
    sleep_diary(order_by: { date: asc }) {
      id
      wake_up_duration
      wake_up_count
      wake_up
      user_id
      time_to_sleep
      grade
      go_sleep
      go_bed
      get_up
      date
      comment
      tags {
        sleep_tag
      }
    }

    sleep_restriction(limit: 2, order_by: { start_date: desc }) {
      before_eficiency
      before_fill_count
      before_time_in_bed
      before_time_sleep
      desired_wake_up
      hour_to_sleep
      new_time_in_bed
      start_date
      user_id
    }
  }
`;
